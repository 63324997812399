import React from 'react'
import { Link } from 'gatsby'
import PreviewCompatibleImage from '../PreviewCompatibleImage'

const PostCard = ({
  featuredImage,
  title,
  slug,
  tags = [],
}) => {

  // make a new comma splited array from single array
  const tag = tags[0].split(',')

  return (
    <div className="max-w-sm rounded-lg overflow-hidden shadow-lg bg-white m-4">
      <Link to={slug}>
        <div class="flex flex-col min-h-full">
          <div class="w-full">
            <PreviewCompatibleImage
              imageInfo={{
                image: featuredImage,
                alt: `featured image thumbnail for post ${title}`
              }}
            ></PreviewCompatibleImage>
          </div>
          <div class="px-6 pt-3 flex-grow">
            <h1 class="text-gray-700 text-3xl">{title}</h1>
          </div>
          <div class="px-5 py-3 flex flex-wrap ">
            {tag.map((tag, i) => (
              <span class="inline-block bg-gray-200 rounded-full px-3 py-1 ml-0 m-2 text-sm font-semibold text-gray-700 ">
                {tag}
              </span>
            ))}
          </div>
        </div>
      </Link>
    </div>
  )
}

export default PostCard
